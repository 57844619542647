<template>
    <div>
        <div class="about-dot"></div>
        <div
            class="
                bg-black
                px-10
                about-h
                w-screen
                flex
                justify-center
                items-center
            "
        >
            <div class="text-center" id="banner">
                <div class="px-10 animate__slow">
                    <p
                        class="
                            font-bold
                            text-3xl
                            lg:text-4xl
                            text-white
                            tracking-wider
                        "
                    >
                        關於優兔
                    </p>
                    <p class="mt-2 text-md text-blue lg:text-2xl">ABOUT US</p>
                </div>
                <div
                    class="blue-line h-1 w-full bg-blue mt-4 mb-28 lg:mb-24"
                ></div>
            </div>
        </div>

        <div class="page about-page">
            <div class="container max-w-5xl m-auto">
                <div class="block lg:flex justify-center">
                    <div class="flex-1 px-6 mb-4">
                        <div class="px-4 py-16 one">
                            <div class="">
                                <img
                                    class="m-auto w-32"
                                    src="/images/why_01.png"
                                />
                            </div>
                            <p class="font-bold text-2xl m-3 text-center">
                                堅實的服務團隊
                            </p>
                            <p class="text-center">
                                團隊核心成員皆有6-10年以上<br />數位行銷與電商經歷
                            </p>
                        </div>
                    </div>
                    <div class="flex-1 px-6 mb-4">
                        <div class="px-4 py-16 two">
                            <div class="">
                                <img
                                    class="m-auto w-32"
                                    src="/images/why_02.png"
                                />
                            </div>
                            <p class="font-bold text-2xl m-3 text-center">
                                彈性的合作模式
                            </p>
                            <p class="text-center">
                                快速抓出客戶需求痛點，<br />提供針對性的規劃
                            </p>
                        </div>
                    </div>
                    <div class="flex-1 px-6 mb-4">
                        <div class="px-4 py-16 three">
                            <div class="">
                                <img
                                    class="m-auto w-32"
                                    src="/images/why_03.png"
                                />
                            </div>
                            <p class="font-bold text-2xl m-3 text-center">
                                豐富的廣告經驗
                            </p>
                            <p class="text-center">
                                熟悉電商生態、各種規模商家<br />以達成期望的ROAS與轉換成本
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img
                    class="hidden md:block max-w-5xl m-auto"
                    src="/images/aboutus_h.png"
                />
                <img class="md:hidden mt-10" src="/images/aboutus_v.png" />
            </div>
        </div>
    </div>
</template>
<script>
import animate from './../animate';
export default {
    data() {
        return {
            imgs: Array.from(Array(69).keys()),
        };
    },
    mounted() {
        if (window.IntersectionObserver) {
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animate('#banner', 'fadeIn');
                    }
                });
            }).observe(document.getElementById('banner'));
        }
    },
};
</script>
<style lang="scss" scoped>
.about-dot {
    height: 50px;
    background: url('/images/dotdot.jpg') repeat-x;
    background-size: 26px;
    @media (max-width: 767px) {
        height: 50px;
        background-size: 24px;
    }
}
.about-h {
    height: 40vh;
}
.about-page {
    margin-top: -20vh;
}
.one {
    background-color: #2c92d5;
}

.two {
    background-color: #37c9ef;
}
.three {
    background-color: #6dc8f9;
}
</style>
